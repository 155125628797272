<template>
    <div class="popup_sub_container">
        <div class="for_inside_addressbookmodal_adj">
            <AddContact
                @closeModal="closeAddAddress"
                v-if="showAddNew"
                :edit="is_edit"
                :editData="edit_data"
            />
        </div>
        <div class="address_book_header_wrapper">
            <div
                :class="
                    category
                        ? 'text_box_container text-box-container-w85'
                        : 'text_box_container text-box-container-w60'
                "
            >
                <input
                    type="text"
                    v-model="search.name"
                    placeholder="Search Name"
                />
            </div>
            <div
                :class="
                    category
                        ? 'text_box_container text-box-container-w85'
                        : 'text_box_container text-box-container-w60'
                "
            >
                <input
                    type="text"
                    v-model="search.address"
                    placeholder="Search Address"
                />
            </div>

            <div class="address_filter_container" v-if="!category && !hideFilter">
                <a class="address_filter_bt">Address Filter</a>
                <div class="address_filter_options">
                    <ul>
                        <!--
							<li>
								<label>
									<input type="checkbox" v-model="filter.address_type.myunit" value="myunit" />
									<span>My Unit</span>
								</label>
							</li>
						-->
                        <li>
                            <label>
                                <input
                                    type="checkbox"
                                    v-model="filter.address_type.internal"
                                    value="internal"
                                    @change="searchCategoryFilter"
                                />
                                <!-- <input type="checkbox" v-model="filter.address_type.internal" value="internal" /> -->
                                <span>Internal</span>
                            </label>
                        </li>
                        <li>
                            <label>
                                <input
                                    type="checkbox"
                                    v-model="filter.address_type.external"
                                    value="external"
                                    @change="searchCategoryFilter"
                                />
                                <!-- <input type="checkbox" v-model="filter.address_type.external" value="external" /> -->
                                <span>External</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <a class="add_new" @click="filteredAddressWithPage">Search</a>
            <!--  <a
					class="add_new"
					v-if="auth().user_type == 'hub_admin' || auth().user_type == 'super_admin'"
					@click="showAddNew = true"
				>Add New</a>-->
        </div>
        <div class="address_book_body_wrapper">
            <table>
                <tr>
                    <td>NAME</td>
                    <td>BRANCH/OFFICE</td>
                    <td>ADDRESS</td>
                    <td>EMAIL</td>
					<td>FLOOR NO</td>
					<td>DESK NO</td>
                    <td>ACTION</td>
                </tr>
                <tbody v-if="!loading">
                    <tr v-for="(address, i) in addresses" :key="i" :class="[{ 'disabled-row' : isAddressDisabled(address)}]">
                        <td>{{ address.name }}</td>
                        <td>{{ address.branch_name || 'N/A' }}</td>
                        <td>
                            <span v-if="address.floor">{{ address.floor }}, </span>
                            <span v-if="address.desk">{{ address.desk }}, </span>
                            <span v-if="address.address_1">{{ address.address_1 }}, </span>
                            <span v-if="address.address_2">{{ address.address_2 }}, </span>
                            {{ address.city }}, 
                            {{ address.state }}, 
                            {{ address.country }}, 
                            {{ address.postal_code }}
                        </td>
                        <td>{{ address.email }}</td>
						<td>{{ address.floor || 'NA' }}</td>
						<td>{{ address.desk || 'NA' }}</td>
                        <td>
                            <label v-if="!hideCheckbox">
                                <input
                                    type="checkbox"
                                    v-model="address.is_checked"
                                    @click="
                                        selectAddress(i, address.is_checked)
                                    "
                                />
                            </label>
                            <a
                                class="edit_icon"
                                @click="editAddress(address)"
                                v-show="
                                    auth().user_type == 'hub_admin' ||
                                        auth().user_type == 'super_admin'
                                "
                            ></a>
                            <a
                                class="delete_icon"
                                @click="deleteAddress(address.id)"
                                v-show="
                                    auth().user_type == 'hub_admin' ||
                                        auth().user_type == 'super_admin'
                                "
                            ></a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <h4 v-if="!loading && addresses.length == 0">No data found</h4>
            <loader size="50" v-if="loading" />
            <paginate  v-show="!loading && addresses.length != 0" v-model="current_page" :page-count="pages"
				active-class="paginate-active" :click-handler="paginateitems" prev-link-class="paginate-previous"
				next-link-class="paginate-next" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"
				:no-li-surround="true">
			</paginate>
        </div>
        <section class="centered addressbook_modal_footer">
            <a class="link_bt bt_cancel" @click="closeModal">Cancel</a>
            <a
                class="link_bt bt_save"
                @click="selectedAddress(addresses)"
                v-if="!hideCheckbox"
                >Select</a
            >
        </section>
    </div>
</template>
<script>
import AddContact from "./AddContact.vue";
export default {
    name: "AddressBookModal",
    components: {
        AddContact,
    },
    data() {
        return {
            showAddNew: false,
            addresses: [],
            address_id: "",
            filter: {
                address_type: {
                    external: true,
                    internal: true,
                    myunit: true,
                },
            },
            searchname: "",
            searchaddress: "",
            searchcategory: "",
            search: {
                name: "",
                address: "",
                category: "",
            },
            is_edit: false,
            edit_data: {},
            loading: true,
            address_filter: {
                category: "",
            },
            pages: 0,
            getall: false,
            current_page: 1,
        };
    },
    created() {
        if (this.category) {
            this.filter.address_type.external = false;
            this.filter.address_type.internal = false;
            this.filter.address_type[this.category] = true;
        }

        //show internal only
        if (this.hideFilter) {
            this.filter.address_type.external = false;
        }
        this.fetchAddress();
    },
    /*  computed: {
    filteredAddress() {
      return this.addresses.filter(address => {
        return address.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },*/
    methods: {
        paginateitems(page) {
            if(this.getall){
                this.filteredAddress(page);
            }else{
                this.fetchAddress(page);
            }
        },
        closeAddAddress() {
            this.showAddNew = false;
            this.is_edit = false;
            this.edit_data = {};
            this.fetchAddress();
        },
        searchCategoryFilter() {
            this.current_page = 1;
            console.log(this.filter.address_type.internal);
            console.log(this.filter.address_type.external);
            if (this.filter.address_type.internal == true) {
                this.searchcategory = "internal";
            } else {
                this.searchcategory = "external";
            }
            this.search.category = this.searchcategory;
            this.filteredAddress();
        },
        filteredAddressWithPage() {
            this.current_page = 1;
            this.filteredAddress();
        },
        filteredAddress(page = 1) {
            this.getall = true;
            // console.log(this.search);
            this.axios
                .post("/api/address/paginatedall?page=" + page, this.search)
                .then((response) => {
                    // console.log(response);
                    this.addresses = response.data.msg.data;
                    this.pages = response.data.msg.last_page;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        selectAddress(index, isChecked) {
            if (this.type != "delivery" || this.callfrom == "single") {
                this.addresses = this.addresses.map((element) => {
                    element.is_checked = false;
                    return element;
                });
                this.addresses[index].is_checked = isChecked;

                let countries = localStorage.getItem('countries');
                countries = JSON.parse(countries);

                let selectedCountry = countries.find((country) => {
                    return country.name == this.addresses[index].country;
                });
                this.addresses[index].country_code = selectedCountry.code;
                this.addresses[index].from_address_book = true;
            }
        },
        editAddress(address) {
            this.is_edit = true;
            this.edit_data = address;
            this.showAddNew = true;
        },
        closeModal() {
            this.$emit("closeModal", {
                type: this.type,
            });
        },
        async fetchAddress(page = 1) {
            this.getall = false;
            this.loading = true;
            this.addresses = [];
            try {
                if (
                    this.filter.address_type.external ||
                    this.filter.address_type.internal
                ) {
                    let response = "";
                    if (
                        this.filter.address_type.external &&
                        this.filter.address_type.internal
                    ) {
                        // console.log(this.search);
                        // response = await this.axios.post("api/address/list");
                        response = await this.axios.post("api/address/paginatedlist?page=" + page);
                        // console.log(response);
                    } else {
                        if (this.filter.address_type.external) {
                            this.address_filter.category = "external";
                        } else if (this.filter.address_type.internal) {
                            this.address_filter.category = "internal";
                        }
                        response = await this.axios.post(
                            "api/address/paginatedlist?page=" + page,
                            this.address_filter
                        );
                    }

                    this.pages = response.data.msg.last_page;
                    this.addresses = response.data.msg.data.map((element) => {
                        element.is_checked = false;
                        // console.log(this.selectedAddresses);
                        if (
                            this.selectedAddresses.length > 0 &&
                            this.selectedAddresses.find(
                                (address) =>
                                    address &&
                                    (address.address_book_id == element.id ||
                                        address.id == element.id)
                            )
                        ) {
                            element.is_checked = true;
                        }
                        return element;
                    });
                }
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        },
        selectedAddress() {
            if (this.callfrom == "batch") {

                let addresses = this.addresses.filter(
                    (element) => element.is_checked == true
                );

                if (addresses.length > 0) {
                    this.$emit("selectedAddress", addresses);
                    this.closeModal();
                } else {
                    this.toast.error("Atleast one address must be selected");
                }
            } else {
                let address = this.addresses.find(
                    (element) => element.is_checked == true
                );
                if (address) {
                    this.$emit("selectedAddress", address);
                    this.closeModal();
                } else {
                    this.toast.error("Please select an address");
                }
            }
        },
        deleteAddress(id) {
            if (confirm("Are you sure you want to delete this address?")) {
                this.axios
                    .delete("/api/address/" + id)
                    .then((response) => {
                        this.toast.success(response.data.msg);
                        this.fetchAddress();
                    })
                    .catch((error) => {
                        console.log("error", error.response);
                    });
            }
        },
        isAddressDisabled(address) {
            if (this.page !== 'courier') {
                return false;
            }

            const address_1 = address.address_1.replace(/\s+/g, ' ').trim().toUpperCase();
            const user_branch_address_1 = this.user_branch_address.address_1.replace(/\s+/g, ' ').trim().toUpperCase();

            if (this.address_type == 'internal' && address_1 != user_branch_address_1) {
                return true;
            }

            if (this.address_type == 'external' && address_1 == user_branch_address_1) {
                return true;
            }

            return false;
        }
    },
    props: {
        type: String,
        category: String,
        callfrom: String,
        hideCheckbox: {
            default: false,
            type: Boolean,
        },
        selectedAddresses: {
            default: () => [],
            type: Array,
        },
        hideFilter: {
            type: Boolean,
            default: false // Make the prop optional
        },
        page: {
            type:String,
            default:'system',
        },
        address_type: {
            type:String,
            default:null,
        },
        user_branch_address: {
            type:Object,
            default:null,
        },
    },
    watch: {
        "filter.address_type.external"() {
            this.fetchAddress();
        },
        "filter.address_type.internal"() {
            this.fetchAddress();
        },
    },
};
</script>
<style scoped>
.address_book_body_wrapper table {
    width: 100%;
}
.for_inside_addressbookmodal_adj {
    position: relative;
    height: 1px; /*udaipp*/
}
.for_inside_addressbookmodal_adj .add_contact_adj {
    position: absolute;
    z-index: 99;
}
input[type="checkbox"] {
    width: 18px;
    height: 18px;
    display: inline-block;
    border: 1px solid #333;
}
.address_book_body_wrapper table tbody tr { 
    font-weight: 400 !important;
}
</style>
<style lang="scss">
.popup_sub_container {
    padding: 20px;
    width: 100%;
    margin: auto;
    background: #fff;
}
.popup_sub_container > section {
    padding: 20px 0 0 0;
}
section.centered.addressbook_modal_footer {
    background: #f5f5f5;
    padding: 10px 0;
}
.address_book_header_wrapper {
    background: #f5f5f5;
    padding: 20px;
    height: 90px;
    display: flex;
    justify-content: space-between;
    .text_box_container {
        width: 30%;
        height: 50px;
        input[type="text"] {
            width: 100%;
            height: 50px;
            background: #fff;
            border: 1px solid #ddd;
            padding: 0 20px;
        }
    }
    .address_filter_container {
        width: 25%;
        height: 50px;
        position: relative;
        background: #06a5ed;
        .address_filter_bt {
            width: 100%;
            height: 50px;
            background: #ddd;
            color: #000;
            text-align: center;
            line-height: 50px;
            display: block;
            border: 1px solid #ccc;
        }
        .address_filter_options {
            width: 100%;
            height: auto;
            min-height: 50px;
            background: #fff;
            position: absolute;
            left: 0;
            top: 50px;
            display: none;
            padding: 0 20px;
            box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
            ul li {
                display: block;
                padding: 10px 0;
                label {
                    cursor: pointer;
                }

                input {
                    display: inline-block;
                }
                span {
                    display: inline-block;
                    margin-left: 20px;
                    font-size: 14px;
                    position: relative;
                    top: -4px;
                }
            }
        }
        &:hover .address_filter_options {
            display: block;
        }
    }
    .add_new {
        background: #06a5ed;
        height: 50px;
        line-height: 50px;
        width: 10%;
        text-align: center;
        display: block;
        border: 1px solid #ddd;
    }
}

.address_book_body_wrapper {
    padding-top: 50px;
    height: calc(100vh - 293px);
    overflow: auto;
    border-bottom: 5px solid #ddd;
    table {
        border-collapse: collapse;
        font-size: 14px;
        td {
            border-bottom: 1px solid #ddd;
            padding: 20px 5px;
            &:first-child {
                min-width: 200px;
            }
            &:last-child {
                min-width: 150px;
            }
            label {
                display: inline-block;
                margin-right: 5px;
            }
            .edit_icon {
                width: 20px;
                height: 20px;
                display: inline-block;
                background: url(../../assets/ico_edit.png) no-repeat;
                background-size: cover;
                margin-right: 5px;
            }
            .delete_icon {
                width: 20px;
                height: 20px;
                display: inline-block;
                background: url(../../assets/ico_delete.png) no-repeat;
                background-size: cover;
                margin-right: 5px;
            }
        }
    }
}

a.link_bt {
    display: inline-block;
    width: 200px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #333;
    color: #ccc;
    margin: 0 20px;
    font-weight: 600;
    text-transform: uppercase;
}
a.link_bt.bt_cancel {
    background: #000;
    color: #fff;
}
a.link_bt.bt_save {
    background: #004a7c;
    color: #fff;
}

i.closeme {
    width: 30px;
    height: 30px;
    background: #000;
    color: #06a5ed;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    border-radius: 100px;
    display: block;
    font-style: normal;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.disabled-row {
  pointer-events: none;
  opacity: 0.5;
  background-color: #f5f5f5;
}
</style>
